import React, {useContext, useEffect, useState} from 'react';
import "../css/PropertyView.css"
import FavButton from "./FavButton";
import {withRouter, useParams, Link, useLocation} from "react-router-dom";
import {apiConst} from "../constant/apiConst";
import {appUtil} from "../constant/appUtil";
import AlertContext from "./context/AlertContext";
import {propertyConst} from "../constant/propertyConst";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import Confirm from "./Confirm";
import {appConst} from "../constant/appConst";
import FavContext from "./context/FavContext";
import ConfirmContext from "./context/ConfirmContext";
import AppDataContext from "./context/AppDataContext";
import LightBox from "./LightBox";
import LightBoxContext from "./context/LightBoxContext";
import Viewer360 from "./Viewer360";
import {appSettings} from "../constant/appConst";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Zoom,FreeMode, Navigation, Thumbs} from "swiper";
import FullScreenButton from "./FullScreenButton";
import FavCompareContext from "./context/FavCompareContext";
import {useTranslation} from "react-i18next";

function PropertyView(props) {

    const {appData, setAppData} = useContext(AppDataContext);
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const {alert,setAlert} = useContext(AlertContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {lightBox,setLightBox} = useContext(LightBoxContext);
    const {favs,setFavs} = useContext(FavContext);
    const { t } = useTranslation();
    const [is360Active,set360Active] = useState(false);
    const [property,setProperty] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [favId,setFavId] = useState(0);
    const [isSoldOrOption,setIsSoldOrOption] = useState("");
    const [isFavWaiting,setIsFavWaiting] = useState(false);
    const [activeContent,setActiveContent] = useState("floorplan");
    const [clickParam,setClickParam] = useState(-1);
    const [isFullScreen,setIsFullScreen] = useState(false);
    const params = useParams()
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);
    const [price, setPrice] = useState(null);
    useEffect(()=>{

        if(user && client)
        {
            apiConst.findPropertyView(user.id,client.id,params.property_id).then(result => {

                if(result.response)
                {
                    let propertyView = result.propertyView;
                    let view_count = parseInt(propertyView.view_count);
                    apiConst.propertyViewUpdate(propertyView.id,++view_count).then(result => {

                    })
                }
                else
                {
                    apiConst.propertyViewCreate(user.id,client.id,params.property_id).then(result => {

                    });
                }
            })
        }

    },[])
    useEffect(() => {
        apiConst.getProperty(params.property_id).then(result => {
            setProperty(result);
            setPrice(result.price);
            if(result.status === appConst.status.sold)
                setIsSoldOrOption(t("sold"));
            else if(result.status === appConst.status.reserved)
                setIsSoldOrOption(t("reserved"));
            else if(result.status === appConst.status.blocked)
                setIsSoldOrOption(t("blocked"));
            else
                setIsSoldOrOption("");

        });



    },[params]);



    useEffect(() => {
        if(favs && property)
        {

            if(favs.length)
            {
                let id = 0
                favs.forEach((fav) => {
                    if(property.id === fav.property.id)
                        id = fav.id
                })
                setFavId(id);
            }
            else
            {
                setFavId(0);
            }

        }
    },[favs,property,favId])


    function onBottomButtonClick(activeContent){
        if(activeContent !== "images" || activeContent !== "floorplan" ) setThumbsSwiper(null);
        setActiveContent(activeContent)
    }




    function checkLogin()
    {
        if(!user || !client)
        {
            setAlert(t("error_no_active_user_or_client"));
            return false;
        }

        return true
    }


    function onFavClick(){

        if(!checkLogin()) return;

        setIsFavWaiting(true);
        if(favId)
        {
            apiConst.favDelete(favId).then(result => {
                if(result.response)
                {
                    setFavId(0)
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    if(newFavs.length)
                    {
                        newFavs.forEach((fav,i) => {
                            if(fav.id === favId) newFavs.splice(i,1);
                        });
                        setFavs(newFavs);
                        sessionStorage.setItem("favs",JSON.stringify(newFavs));

                    }
                    setAlert(t("property_view.fav_deleted"));
                }
                else {
                    setAlert(t("error_server"));
                }
            })
        }
        else
        {
            apiConst.favCreate(client.id,property.id).then(result => {
                if(result.response)
                {
                    setFavId(result.propertyFav.id);
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    newFavs.push(result.propertyFav);
                    setFavs(newFavs);
                    sessionStorage.setItem("favs",JSON.stringify(newFavs));
                    setAlert(t("property_view.fav_added"));
                }
                else {
                    setAlert(t("error_server"));
                }
            })
        }
    }


    function getFields(status)
    {
        let fields = {
            user_id : user.id,
            client_id : client.id,
            presentation_id : sessionStorage.getItem("presentation_id"),
            property_id : property.id,
            status : status
        }
        return fields;
    }

    function onBuyClick(){
        if(!checkLogin()) return;
        setConfirm({show:true,text:t("property_view.buy_action_text"),onConfirm:() => { onConfirm(appConst.status.sold)} })

    }

    function onOptionClick(){
        if(!checkLogin()) return;
        setConfirm({show:true,text:t("property_view.reserve_action_text"),onConfirm:() => { onConfirm(appConst.status.reserved)} })
    }

    function onCancelClick(){
        if(!checkLogin()) return;
        setConfirm({show:true,text:t("property_view.cancel_action_text"),onConfirm:() => { onConfirm(appConst.status.available)} })
    }

    function onPresaleClick(e){
        document.querySelectorAll(".price-change-button").forEach(item => item.classList.remove("active"))
        e.currentTarget.classList.add("active")
        setPrice(property.price)
    }

    function onSaleClick(e){
        document.querySelectorAll(".price-change-button").forEach(item => item.classList.remove("active"))
        e.currentTarget.classList.add("active")
        setPrice(property.price + (property.price * appData.project.discount_percent/100))
    }

    function onConfirm(status){

        let fields = getFields(status)

        apiConst.propertyUpdate(property.id,fields).then(result => {

            if(result.response)
            {
                let newAppData = {...appData}
                newAppData.properties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status = status;
                    }
                });
                newAppData.filteredProperties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status = status;
                    }
                });

                newAppData.floorStatuses = newAppData.getFreshFloorStatuses();
                newAppData.buildingStatuses = newAppData.getFreshBuildingStatuses();
                window.updateBuildingStatuses(newAppData.buildingStatuses);
                setAppData(newAppData);

                let message;
                switch (status)
                {
                    case appConst.status.available:
                        message = t("property_view.canceled_success");
                        break;
                    case appConst.status.sold:
                        message = t("property_view.sold_success");
                        break;
                    case appConst.status.reserved:
                        message = t("property_view.reserved_success");
                        break;
                }

                setTimeout(()=> {
                    if(status === appConst.status.available)
                    {
                        apiConst.propertyActionDelete(fields.property_id).then(result => {
                            if (result.response) {
                                //console.log("propertyActions silindi")
                            }
                        })
                    }
                    else {
                        apiConst.propertyActionCreate(fields).then(result => {

                            if (result.response) {
                                //console.log("propertyActions oluştu")
                            }
                        })
                    }
                },1000)

                setAlert(message);
                setIsSoldOrOption(status);



            }
        })



    }

    function onClickFullScreen(){
        setIsFullScreen(!isFullScreen)
    }



  return (

    <section className = {isFullScreen ? "primary property-view view-fullscreen" : "primary property-view"}>
        <div className="property-view-container">
            <button className="close-button colored" onClick={() => props.history.goBack()}>
                <div></div>
                <div></div>
            </button>

            {property && <div className="main-container">

                <div className="row h-100 no-gutters">
                    <div className="col-9" onContextMenu={(e)=> e.preventDefault()}>
                        {isSoldOrOption && <div className="status-label">{isSoldOrOption}</div>}

                        {activeContent === "images" &&
                            <div className={"h-100"}>
                            <Swiper
                                zoom={true}
                                spaceBetween={0}
                                slidesPerView={1}
                                thumbs={{ swiper: thumbsSwiper }}
                                onSwiper={(swiper) => console.log(swiper)}
                                navigation={true}
                                modules={[Zoom,FreeMode, Navigation, Thumbs]}
                                className="property-swiper"
                            >
                                {property.images.map ((item,i) => {
                                    return (<SwiperSlide key={i}>
                                        <div className="swiper-zoom-container">
                                            <img src={apiConst.root+"/uploads/"+item} />
                                        </div></SwiperSlide>)
                                })}

                            </Swiper>
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={false}
                                spaceBetween={10}
                                breakpoints={{
                                    768 : {
                                        slidesPerView:4,
                                    },
                                    1250: {
                                        slidesPerView: 6,
                                    },
                                    1441: {
                                        slidesPerView: 8,
                                    },
                                }}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="property-thumb-swiper"
                            >
                                {property.images.map ((item,i) => {
                                    return (<SwiperSlide key={i}><img src={apiConst.root+"/uploads/"+item} /></SwiperSlide>)
                                })}
                            </Swiper>
                        </div>
                        }
                        {activeContent === "floorplan" &&
                            <div className={"h-100"}>
                                <Swiper
                                    zoom={true}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    navigation={true}
                                    modules={[Zoom,FreeMode, Navigation]}
                                    className="property-swiper"
                                >
                                    {property.plans.map ((item,i) => {
                                        return (<SwiperSlide key={i}>
                                            <div className="swiper-zoom-container">
                                                <img src={apiConst.root+"/uploads/"+item} />
                                            </div></SwiperSlide>)
                                    })}

                                </Swiper>

                            </div>
                        }
                        {activeContent === "360" && <Viewer360 link={propertyConst.vr360[property.room_type_folder]} />}

                        {activeContent !== "360" && <FullScreenButton onClick={onClickFullScreen} /> }

                    </div>
                    <div className="col-3">
                        <div className="property-details-container">

                            <div className="property-details">

                                    <h1 className="property-title">{property.number} <br/> <span>{property.room_type.name}</span><span>  </span></h1>



                                {appSettings.showPrices && <div className={"total-price-container"}><p
                                    className="property-price">{appUtil.formatPrice(price)} </p>
                                    <button className="price-change-button default-button active"
                                            onClick={onPresaleClick}>{t("property_view.presale")}</button>
                                    <button className="price-change-button default-button"
                                            onClick={onSaleClick}>{t("property_view.sale")}</button>
                                </div>}
                                    <div className={"fav-share-container"}>
                                        <FavButton isFav={favId} isWaiting={isFavWaiting} onClick={onFavClick}  />
                                        { !isSoldOrOption && <button className="action-button default-button" onClick={onOptionClick}>{t("reserve")}</button>}
                                        { !isSoldOrOption && <button className="action-button default-button d-none" onClick={onBuyClick}>{t("buy")}</button>}
                                        { isSoldOrOption && <button className="action-button default-button" onClick={onCancelClick}>{t("cancel")}</button>}
                                    </div>


                            </div>
                            <div className="bottom-buttons">
                                {appSettings.hasInfo && <Link className={"btn-information text-uppercase"} to={{pathname : apiConst.root+"/img/properties/"+property.code+".pdf"}} target={"_blank"}>{t("property_view.info")}</Link>}
                                <button className={activeContent==="images" ? "btn-images active text-uppercase": "btn-images text-uppercase"} onClick={() => onBottomButtonClick("images")}>{t("property_view.images")}</button>
                                <button className={activeContent==="floorplan" ? "btn-floorplan active text-uppercase": "btn-floorplan text-uppercase"} onClick={() => onBottomButtonClick("floorplan")}>{t("property_view.floor_plan")}</button>
                                {appSettings.has360 && !appSettings.is360External && <button className={activeContent==="360" ? "btn-360 active text-uppercase": "btn-360 text-uppercase"} onClick={() => onBottomButtonClick("360")}>{t("property_view.virtual_tour")}</button>}
                                {appSettings.has360 && appSettings.is360External && <Link className="btn-360 text-uppercase" to={{pathname : propertyConst.vr360[property.room_type_folder]}} target={"_blank"}>{t("property_view.virtual_tour")}</Link>}

                                <button className={activeContent==="finance" ? "btn-finance text-uppercase active d-none": "btn-finance text-uppercase d-none"} onClick={() => onBottomButtonClick("finance")}>{t("property_view.finance")}</button>

                            </div>

                        </div>


                    </div>
                </div>

            </div>}
        </div>




    </section>

  );
}

export default withRouter(PropertyView);